import axios, { AxiosError, AxiosResponse, AxiosBasicCredentials } from 'axios';
import { LOG_URL } from './constants';
import { AttributeValueBase, AttributeTextValue } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';
import moment, { Moment } from 'moment';
import * as Msdyn365 from '@msdyn365-commerce/core';

export const Get = (url: string, accept: 'xml' | 'json' | 'text'): Promise<AxiosResponse | void> => {
    return axios({
        url: url,
        headers: {
            accept: `application/${accept}`
        },
        method: 'GET',
        timeout: 30000
    }).catch(async (err: AxiosError) => {
        // await Log('Error', err);
        return;
    });
};

export const Post = (
    url: string,
    data: object | string,
    method: 'POST' | 'PUT' | 'DELETE',
    accept: 'xml' | 'json' | 'text',
    contentType: 'xml' | 'json' | 'text' | 'x-www-form-urlencoded' | 'multipart/form-data',
    additionalHeaders: object = {},
    auth?: { auth: AxiosBasicCredentials }
): Promise<AxiosResponse | void> => {
    const _auth = auth !== undefined ? auth : {};
    console.log(_auth);
    return axios({
        url: url,
        method: method,
        timeout: 30000,
        ..._auth,
        headers: {
            accept: `application/${accept}`,
            contentType: contentType === 'multipart/form-data' ? contentType : `application/${contentType}`,
            ...additionalHeaders
        },
        data: contentType === 'json' ? JSON.stringify(data) : data
    }).catch(async (err: AxiosError) => {
        // await Log('Error', err);
        return;
    });
};

export const Log = (logType: 'Error' | 'Trace', log: string | object) => {
    // TODO: Configure this function to post errors or trace logs to a server
    const _log = typeof log === 'object' ? (Object.keys(log).length ? JSON.stringify(log) : log.toString()) : log.toString();
    const data = {
        LogType: logType,
        Log: _log
    };
    return Post(LOG_URL, data, 'POST', 'json', 'json');
};

export const IsObjectEmpty = (object: any) => {
    for (var prop in object) {
        if (object.hasOwnProperty(prop)) {
            return false;
        }
    }
    return true;
};
export const enumKeysToList = <O extends object, K extends keyof O = keyof O>(obj: O): K[] => {
    return Object.keys(obj).filter(k => Number.isNaN(+k)) as K[];
};

export const _validateBirthDate = (birthday?: string | undefined): boolean => {
    const input = birthday ? birthday.toString() : birthday;

    if (input !== undefined && input !== '') {
        var date_regex = /^(0?[1-9]|[12][0-9]|3[01])\/(0?[1-9]|1[012])\/\d{4}$/;
        if (!date_regex.test(input)) {
            return false;
        } else {
            const formatedDate: Moment = moment(birthday, 'DD/MM/YYYY', true);
            if (!formatedDate.isValid()) {
                return false;
            }
            const year: number = formatedDate.year();
            const currentYear: number = new Date().getFullYear();
            if (year >= currentYear) {
                return false;
            } else {
                return currentYear - year >= 18 ? true : false;
            }
        }
        // const timeDiff = Math.abs(Date.now() - new Date(input).getTime());
        // return Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25) >= 18 ? true : false;
    }
    return false;
};

export const addUpdateCheckoutExtensionProperties = (
    newProperties: { key: string; value: string }[],
    checkoutExtensionProps: AttributeValueBase[]
): AttributeValueBase[] => {
    const _checkoutExtensionProps = [...checkoutExtensionProps.slice()];

    newProperties.forEach(property => {
        const extPropertyIndex = _checkoutExtensionProps.findIndex(p => p.Name === property.key);
        const attribute: AttributeValueBase & AttributeTextValue = {
            // @ts-ignore
            '@odata.type': '#Microsoft.Dynamics.Commerce.Runtime.DataModel.AttributeTextValue',
            Name: property.key,
            ExtensionProperties: [
                {
                    Key: property.key,
                    Value: { StringValue: property.value }
                }
            ],
            TextValue: property.value,
            TextValueTranslations: []
        };
        if (extPropertyIndex !== -1) {
            _checkoutExtensionProps.splice(extPropertyIndex, 1, attribute);
        } else {
            _checkoutExtensionProps.push(attribute);
        }
    });
    return _checkoutExtensionProps;
};

export const getCheckoutExtensionPropertyValue = (checkoutExtensionProps: AttributeValueBase[], key: string): string | undefined => {
    const _checkoutExtensionProps = [...checkoutExtensionProps.slice()];
    const property = _checkoutExtensionProps.find(property => property.Name === key) as AttributeValueBase & AttributeTextValue;
    return property?.TextValue;
};

/**
 * Tries to get from the key vault, but if it failes and environment != production, it'll parse it from the key passed in, e.g. VaultKeyWeWant|FallbackValueWeNeed
 */
export async function getSecretOrFallbackOutsideProduction(key: string, ctx: Msdyn365.IActionContext): Promise<string | undefined> {
    let value = await Msdyn365.msdyn365Commerce.secretManager
        ?.getSecretValue(key, ctx.requestContext.apiSettings.baseUrl)
        .then(APIKey => {
            if (!APIKey?.error) {
                return APIKey?.value;
            }
            return undefined;
        })
        .catch(error => {
            console.log(error);
            return undefined;
        });
    // if not in production, valut key can be VaultKeyWeWant|FallbackValueWeNeed
    if (!value && key.indexOf('|') < key.length && ctx.requestContext.app.config.environmentType !== 'production') {
        return (value = key.substring(key.indexOf('|') + 1));
    }

    return value;
}
