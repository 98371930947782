export const SMART_DEBIT_API_BASE_URL = 'https://secure.ddprocessing.co.uk';
export const SMART_DEBIT_API_USERNAME = 'centcooptestapi';
export const SMART_DEBIT_API_PASSWORD = 'Centen123!';

export const SMART_DEBIT_API_TEST_BASE_URL = 'https://sandbox.ddprocessing.co.uk';
export const SMART_DEBIT_API_TEST_USERNAME = 'centcooptestapi';
export const SMART_DEBIT_API_TEST_PASSWORD = 'Centen123!';

export const USER_MESSAGE_LOADING = 'Loading...';
export const USER_MESSAGE_ERROR = 'Sorry, something went wrong.';

export const YEXT_API_KEY = 'api_key=aa41207f4da0b3fc80322ff52c7316bb';
export const YEXT_API_KEY_VERSION = 'v=20181201';

// TODO: Put your API url here for logging error or trace information to the server
export const LOG_URL = '';

export const POSTCODE_REGEX = /^((GIR 0AA)|((([ABCDEFGHIJKLMNOPRSTUWYZ][0-9][0-9]?)|(([ABCDEFGHIJKLMNOPRSTUWYZ][ABCDEFGHKLMNOPQRSTUVWXY][0-9][0-9]?)|(([ABCDEFGHIJKLMNOPRSTUWYZ][0-9][ABCDEFGHJKSTUW])|([ABCDEFGHIJKLMNOPRSTUWYZ][ABCDEFGHKLMNOPQRSTUVWXY][0-9][ABEHMNPRVWXY])))) [0-9][ABDEFGHJLNPQRSTUWXYZ]{2}))$/gim;
// this is the regex fom OWASP that eslint recommends!!!
// https://owasp.org/www-community/OWASP_Validation_Regex_Repository
// eslint-disable-next-line security/detect-unsafe-regex
export const EMAIL_REGEX = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
export const PHONE_REGEX = /^[0-9]{0,15}$/;
// cookie name for storing selected plan during guided journey
export const SELECTED_PLAN_PRODUCTID = 'cec-productInCartId';
// cookie name for storing selected funeral home during guided journey
export const SELECTED_FUNERAL_HOME = 'cec-funeralHomeId';
